<template>
  <div class="content">
    <!-- 内容管理 -->
    <div class="search-top">
      <el-button type="primary" size="mini" @click="goToAdd">新增</el-button>
    </div>
    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
        size="small"
      >
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.keyWord"
            placeholder="内容标题"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchBtn"
            >搜索</el-button
          >
          <el-button size="mini" @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column
        prop="menuName"
        label="所属栏目"
        min-width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="contentTitle"
        label="标题"
        min-width="250"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="contentImage"
        label="封面图"
        min-width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <img
            :src="
              '/qixianggongyingpingtai_api/common/backstage/file/fileDownLoad?filePath=' +
              scope.row.contentImage
            "
            style="width: 80px"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="contentType"
        label="内容属性"
        min-width="100"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <div v-if="scope.row.contentType === '1'">一般</div>
          <div style="display: flex" v-else-if="scope.row.contentType === '2'">
            热点
            <img
              src="./img/huo.png"
              style="display: block; width: 20px; margin-left: 10px"
              alt=""
            />
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contentAbstract"
        label="内容摘要"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="170">
      </el-table-column>
      <el-table-column label="操作" v-slot="scope" min-width="80">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="primary"
            @click="goToEdit(scope.row)"
            >编辑</el-link
          >
          <el-link
            class="ml10r"
            :underline="false"
            type="danger"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryContentInfoByPage,
  deleteContentInfoById,
} from "../../api/contentManagement";
export default {
  data() {
    return {
      listQuery: {
        keyWord: "",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    //列表查询
    getTableList(page) {
      this.listQuery.pageNum = page;
      this.getTableData();
    },
    getTableData() {
      queryContentInfoByPage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    // 重置
    resetBtn() {
      this.getTableData();
    },
    // 新增广告
    goToAdd() {
      this.$router.push({
        path: "/home/informationManage/contentManagement/addContent",
        query: {
          localEditType: "add",
        },
      });
    },

    // 编辑
    goToEdit(row) {
      this.$router.push({
        path: "/home/informationManage/contentManagement/editContent",
        query: {
          localEditType: "edit",
          id: row.contentId,
        },
      });
    },

    // 删除
    deleteOption(row) {
      deleteContentInfoById({
        id: row.contentId,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getTableList();
        }
      });
    },
  },
  filters: {
    filterJumpType(val) {
      if (val === 1) {
        return "外部链接";
      } else if (val === 2) {
        return "内部";
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>